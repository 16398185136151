import React from "react";
import ReactDOM from "react-dom";
//=============== Redux =================>

//=============== End Redux =================>
import App from "./app/app";

// const store = configureStore();
ReactDOM.render(
  // <ReduxProvider store={store}>
  //   {/* <React.StrictMode> */}
  //   <Suspense fallback={<div> Loading ...</div>}>
      <App />,
  //   </Suspense>
  //   {/* </React.StrictMode>{" "} */}
  // </ReduxProvider>,
  document.getElementById("root")
);
