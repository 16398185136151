import React from "react";


import { NavLink, useLocation } from "react-router-dom";
const Header = (props: any) => {
  const location = useLocation();


  let pathSplit;
  if (location.pathname === "/") {
    pathSplit = true;
  } else {
    pathSplit = false;
  }


  return (
    <div className={`main-header ${pathSplit ? "homeHeader" : ""}`}>
      <div className='' >
        <div className="topbar">
          <div className="left">
            <ul className="list-unstyled">
             
            </ul>
          </div>
          <div className="right">
            <ul className="list-unstyled">
             
            </ul>
          </div>
        </div>

        <div className="header-items">
          <div className="container">
          {/* <NavLink to="/" className="navbar-brand"> */}
              <img alt="" src="/content/images/icons/farabi.png" />
            {/* </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;