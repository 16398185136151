
import React from 'react';

function Footer(props: any) {
 
  return (
    <>
      <div className="main-footer">
        <div className="topfooter">
          <div className="container">
            
            <div className="row">
              <div className="col-sm-12">
                <p className="copyright">
                © 2020. 
                </p>
              </div>
            </div>
          </div>
        </div>
       

      </div>
    </>
  );
};

export default Footer;