import { handleResponse, handleError } from "./apiUtils";

export function ApiHeader(isFormData = false) {
  let token = localStorage.getItem("token");
  if (token) {
    if (isFormData)
      return {
        "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "X-Requested-With",
        Authorization: `Bearer ${token}`,
      };
    else
      return {
        "content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "X-Requested-With",
        Authorization: `Bearer ${token}`,
      };
  } else {
    if (isFormData)
      return {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        // "Access-Control-Allow-Headers": "X-Requested-With",
      };
    else
      return {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        // "Access-Control-Allow-Headers": "X-Requested-With",
      };
  }
}

export function GetApi(endPoint, queryParam) {
//  const baseUrl = "http://localhost:3100/";
 const baseUrl = "https://soso.alfarabischool.com/";

  let url = baseUrl + endPoint;
  if (queryParam)
    url = url + "?" + queryParam;

  const _header = ApiHeader();
  return fetch(url, {
    method: "GET",
    headers: _header,
  })
    .then(handleResponse)
    .catch(handleError);
}