import React, { useEffect, useState, useRef } from "react";
import { format, add } from "date-fns";
import { useParams } from "react-router-dom";
import { getListApi, getPassCodeApi } from "../../../api/zoomApi";
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

function News() {
  const { id }: any = useParams();
  const [meetingsData, setData] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextPage, setNextPage] = useState<string>('');
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [passCode, setpassCode] = useState<string>('');

  const onHide = () => {
    setDisplayResponsive(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getListApi("token=" + id).then((res: any) => {
      if (res && res.data && res.data.total_records && res.data.total_records > 0) {
        setData(res.data.meetings);
        setNextPage(res.data.next_page_token);
      }
      setLoading(false);
    })
  }, [])

  var date = new Date();
  const [fromDate, setFromDate] = useState<any>(add(date, { days: -1 }));
  const [toDate, setToDate] = useState<any>(date);
  const search = () => {
    setLoading(true);
    getListApi("token=" + id + "&from=" + format(fromDate, "yyyy-MM-dd") + "&to=" + format(toDate, "yyyy-MM-dd")).then((res: any) => {
      if (res && res.data && res.data.total_records && res.data.total_records > 0) {
        setData(res.data.meetings);
        setNextPage(res.data.next_page_token);
      }
      setLoading(false);
    })
  }

  const formatDate = (value: any) => {
    if (value !== '') {
      return format(Date.parse(value.start_time), "yyyy-MM-dd hh:mm a");
    } else {
      return "";
    }
  }

  const formatLink = (value: any) => {
    if (value !== '') {
      return <a href={value.play_url} target="_blank">Watch</a>;
    } else {
      return "";
    }
  }

  const formatPassCode = (value: any) => {
    if (value !== '') {
      return <button className="btn-get-pass" onClick={() => getPassCode(value.meeting_id)}>Get Passcode</button>;
    } else {
      return "";
    }
  }

  const getPassCode = (val: any) => {
    var _ = require('lodash');
    let meetingIndex = _.findIndex(meetingsData, ['uuid', val])
    if (meetingIndex >= 0)
      val = meetingsData[meetingIndex].id;

    getPassCodeApi("token=" + id + "&mId=" + val).then((res: any) => {
      if (res && res.data) {
        setpassCode(res.data.password);
        setDisplayResponsive(true);
      }
    })
  }

  const nextPageAction = () => {
    setLoading(true);
    getListApi("token=" + id + "&from=" + format(fromDate, "yyyy-MM-dd") + "&to=" +
      format(toDate, "yyyy-MM-dd") + "&page=" + nextPage).then((res: any) => {
        if (res && res.data && res.data.total_records && res.data.total_records > 0) {
          setData(res.data.meetings);
          setNextPage(res.data.next_page_token);
        }
        setLoading(false);
      })

    // alert(nextPage)
  }


  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="orders-subtable">
        <h5>Records for {data.topic}</h5>
        <DataTable value={data.recording_files} stripedRows>

          <Column field="recording_type" header="Type" ></Column>
          <Column field="play_url" header="View" body={formatLink} />
          <Column field="play_url" header="Password" body={formatPassCode} />
        </DataTable>
      </div>
    );
  }

  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef<any>(null);
  const reset = () => {
    setGlobalFilter('');
    dt.current.reset();
  }


  const header = (
    <div className="table-header">
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  return (
    <>

      <Dialog header="Pass Code" visible={displayResponsive} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '25vw' }} >
        <p className="pp-passcode">{passCode}</p>
      </Dialog>

      <div className="technical-support">

        <div className="content">
          <div className="form">
            <h3>
              Records Search
            </h3>
            <div className="fields">
              <div className="row">

                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>From Date</label>
                    <Calendar id="icon" value={fromDate} dateFormat="yy-mm-dd" onChange={(e) => setFromDate(e.value)} className="form-controlcal" showIcon />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>To Date</label>
                    <Calendar id="icon" value={toDate} dateFormat="yy-mm-dd" onChange={(e) => setToDate(e.value)} className="form-controlcal" showIcon />
                  </div>
                </div>


                <div className="col-sm-12">
                  <div className="form-group">
                    <button className="btn" onClick={() => search()}>Search</button>
                  </div>
                </div>
              </div>



              <div className="row">
                <div className="card">
                  <DataTable ref={dt} header={header} globalFilter={globalFilter} scrollable scrollHeight="300px" stripedRows loading={loading}
                    value={meetingsData} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate} dataKey="id" className="p-datatable-customers">
                    <Column expander style={{ width: '3em' }} />
                    <Column field="topic" header="Topic" />
                    <Column field="start_time" header="Time" body={formatDate} />

                  </DataTable>
                </div>
                {
                  nextPage !== '' ? (
                    <>
                      <div className="post-author">
                        <button className="btn" onClick={() => nextPageAction()}>Next</button>
                      </div>
                    </>
                  ) : ''
                }
              </div>




            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;