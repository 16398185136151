import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import "./app.scss";
import Footer from "./shared/layout/footer/footer";
import Header from "./shared/layout/header/header";
import News from "./web/news/news";
import "bootstrap/scss/bootstrap.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


function App() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route path="/records/:id" component={News} />
        <Route path="/:id" component={News} />
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
