export async function handleResponse(response) {
  if (response.ok) {
    let requestResponse = await response.json();

    return requestResponse;
  } else {
    // debugger;
    let requestResponse = {
      haseError: true,
      status: response.status,
      payload: "",
    };

    return requestResponse;
  }
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  console.error("API call failed. " + error);
  throw error;
}
